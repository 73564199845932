import React, {useState, useContext} from 'react';
import GithubContext from '../../context/github/githubContext';
import AlertContext from '../../context/alert/alertContext';

const Search = () => {

  const githubContext = useContext(GithubContext);
  const alertContext = useContext(AlertContext);

  const [text, setText] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    if(text === ''){
        alertContext.setAlert('Please enter something..!', 'light')      
    }else{
        githubContext.searchUsers(text);
        setText('');
      }
  } 

  //setting the change on the input with the name attrib dynamically
 const onChange = e => setText(e.target.value);

    return (
      <div>
        <form className="form"  onSubmit = {onSubmit} >
          <input type="text" name="text" value={text} onChange={onChange}placeholder="Search users.."/>
          <input type="submit" value="Search" className="btn btn-dark btn-block"/>
        </form>
        {githubContext.users.length > 0 &&
        <button className="btn btn-light btn-block" onClick={githubContext.clearUsers}>Clear</button>}
      </div> 
    )
}

export default Search
