import React from 'react'

const PageNotFound = () => {
  return (
    <div>
      <h1>Not Found</h1>
      <p className="lead">The page does not exist...</p>
    </div>
  )
}
export default PageNotFound;