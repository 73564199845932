import React from 'react'

 const About = () => {
  return (
    <>
      <h1>About this app</h1>
      <p>App to search github users</p>
      <p>Version 1.0</p>
    </>
  )
}

export default About;