import React from 'react';
import Navbar from './components/layouts/Navbar';
import './App.css';
import Home from './components/pages/Home';
import PageNotFound from './components/pages/PageNotFound';
import Alert from './components/layouts/Alert';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import About from './components/pages/About';
import User from './components/users/User';
import GithubState from './context/github/GithubState'
import AlertState from './context/alert/AlertState'


const App = () => {

  // async componentDidMount(){
  //  this.setState({loading: true}); 
  //  const res =  await axios.get(`https://api.github.com/users?client_id=${process.env.REACT_GIT_API_KEY}&client_secret=${process.env.REACT_GIT_API_SECRET}`)
  //  this.setState({users: res.data, loading: false});
  // }
    return ( 
      <GithubState>
        <AlertState>
      <Router>
      <div className = "App" >
        <Navbar />
        <div className="container">
        <Alert/>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/about' component={About}/>
          <Route exact path='/user/:login' component={User}/>
          <Route component={PageNotFound}/>
        </Switch>
        </div>
      </div>
      </Router>
      </AlertState>
    </GithubState>   
    ); 
  }

export default App; 